import { BrowserRouter as Router } from 'react-router-dom';
import TapoutsRoutes from './components/core/TapoutsRoutes';
import { CoreContextProvider } from './components/core/CoreContextProvider';
import AuthContextProvider from './components/core/AuthContextProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-time-picker/assets/index.css';

function App() {
  return (
    <Router>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CoreContextProvider>
        <AuthContextProvider>
          <TapoutsRoutes />
        </AuthContextProvider>
      </CoreContextProvider>
    </Router>
  );
}
export default App;
