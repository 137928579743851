import PropTypes from 'prop-types';

const Header = ({ children }) => {
  return (
    <div>
      <div>
        <div className="px-3 md:border-b-0 fixed inset-x-0 top-0 px-8 py-5 bg-blue-900 z-50">
          <div className="h-full flex items-center">{children}</div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Header;
