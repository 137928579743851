import PropTypes from 'prop-types';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useCore } from '../../core/CoreContextProvider';
import { setDoc } from '@firebase/firestore';
import { doc } from '@firebase/firestore';
import log from '../../core/log';
import LoadingSpinner from '../../common/Loading';
import { updateFieldInDocument } from '../../common/Firebase';

export const FeedBack = ({
  participants,
  setshowFeedBack,
  session_doc_id,
  session_id,
  podId,
  getParticipants,
  setDetails,
}) => {
  const { firestore } = useCore();
  const [feedBacks, setFeedBacks] = useState({});
  const [loading, setLoading] = useState(false);

  const setChildData = (value, id, key, feedBackId) => {
    let tempObj = { ...feedBacks };
    tempObj[id] = tempObj[id] ? tempObj[id] : {};
    tempObj[id][key] = value;
    tempObj[id]['feedBackId'] = feedBackId;
    setFeedBacks(tempObj);
    if (key == 'present') {
      participants.forEach((e) => {
        if (e.uid == id) {
          e.present = value;
        }
      });
      setDetails(participants);
    }
  };

  const sendFeedBack = async () => {
    setLoading(true);

    try {
      await Promise.all(
        Object.keys(feedBacks).map(async (id) => {
          if (feedBacks[id].feedBackId) {
            delete feedBacks[id].feedBackId;
            try {
              await updateFieldInDocument(
                firestore,
                `children_pod_data/${id}/${podId}`,
                session_doc_id,
                { ...feedBacks[id] }
              );
              toast.success('Feedback Updated Successfully');
              getParticipants();
              setshowFeedBack(false);
            } catch (err) {
              console.log(err);
              toast.error('Error in Adding FeedBack');
              return false;
            }
          } else {
            feedBacks[id].present = feedBacks[id].present
              ? feedBacks[id].present
              : false;
            delete feedBacks[id].feedBackId;
            try {
              await setDoc(
                doc(
                  firestore,
                  `children_pod_data/${id}/${podId}/${session_doc_id}`
                ),
                { ...feedBacks[id], session_id: session_id }
              );
              toast.success('Feedback Added Successfully');
              getParticipants();
              setshowFeedBack(false);
            } catch (err) {
              toast.error('Error in Adding FeedBack');
              return false;
            }
          }
        })
      );
    } catch (error) {
      log('Firebase error: while getting feedback details', error);
    }

    setLoading(false);
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && participants.length !== 0 && (
        <tr className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <td className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></td>

          <td className="modal-container bg-white w-5/6 max-h-[80%] mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div className="modal-close cursor-pointer">
                  <span
                    onClick={() => setshowFeedBack(false)}
                    className="bg-close block cursor-pointer"
                  ></span>
                </div>
              </div>

              <div className="p-5">
                <div className="preview">
                  <table className="mt-5 w-full">
                    <thead className="">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                        >
                          Children ID
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                        >
                          Children Name
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                        >
                          Present
                        </th>

                        <th
                          scope="col"
                          className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                        >
                          Feeedaback
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {participants.map((child) => {
                        return (
                          <tr
                            key={child.children_id}
                            className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                          >
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                              {child.children_id}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                              {`${child.first_name} ${child.last_name}`}
                            </td>
                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                              <input
                                checked={child.present}
                                onChange={(e) => {
                                  setChildData(
                                    e.target.checked,
                                    child.uid,
                                    'present',
                                    Object.prototype.hasOwnProperty.call(
                                      child,
                                      'feedbackId'
                                    )
                                  );
                                }}
                                type="checkbox"
                              />
                            </td>

                            <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                              <textarea
                                onChange={(e) => {
                                  setChildData(
                                    e.target.value,
                                    child.uid,
                                    'feedback',
                                    Object.prototype.hasOwnProperty.call(
                                      child,
                                      'feedbackId'
                                    )
                                  );
                                }}
                                defaultValue={
                                  child.feedback ? child.feedback : ''
                                }
                                id="c-feedback"
                                type="text"
                                className="pt-6 pb-1 shadow-md block w-full px-4 shadow-md mt-0 bg-transparent border border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-gray-400 border-gray-200 rounded-md"
                                placeholder=" "
                              ></textarea>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="pb-5 text-center">
                <button
                  onClick={() => sendFeedBack()}
                  disabled={feedBacks.length !== 0 ? false : true}
                  className={`w-40 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none   hover:shadow-lg focus:outline-none ${
                    feedBacks.length !== 0
                      ? 'bg-blue-900 hover:bg-yellow-500'
                      : 'bg-blue-200'
                  }`}
                >
                  Save
                </button>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

FeedBack.propTypes = {
  participants: PropTypes.array.isRequired,
  setshowFeedBack: PropTypes.func.isRequired,
  session_doc_id: PropTypes.string.isRequired,
  session_id: PropTypes.string.isRequired,
  podId: PropTypes.string.isRequired,
  getParticipants: PropTypes.func.isRequired,
  setDetails: PropTypes.func,
};
