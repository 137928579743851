import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

function DateOverridePopup({
  open,
  handleClose,
  timings,
  overrideData,
  handleAddSpecificDates,
}) {
  const [todaysDate, setTodaysDate] = useState('');

  const [specificDate, setSpecificDate] = useState([
    {
      date: '',
      day: '',
    },
  ]);

  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString();
    const day = date.getDate().toString();
    const todaysDate =
      year + '-' + (month.length === 1 ? '0' + month : month) + '-' + day;
    setTodaysDate(todaysDate);
  }, []);

  useEffect(() => {
    if (overrideData.length) {
      setSpecificDate(overrideData);
    }
  }, [overrideData]);

  const handleSpecificDateChange = (e, index) => {
    const { value } = e.target;
    const selectedDayNumber = new Date(value).getUTCDay();
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    let selectedDayString = days[selectedDayNumber];
    const tempSpecificDate = [...specificDate];
    tempSpecificDate[index].date = value;
    tempSpecificDate[index].day = selectedDayString;
    tempSpecificDate[index].timings = [];
    tempSpecificDate[index].timings.push({
      start_time: timings[0],
      end_time: timings[1],
    });
    setSpecificDate(tempSpecificDate);
  };

  const handleAddTimeSpecificDate = (index) => {
    const tempSpecificDate = [...specificDate];
    const lastIndexEndTime =
      tempSpecificDate[index].timings.length > 0
        ? tempSpecificDate[index].timings[
            tempSpecificDate[index].timings.length - 1
          ].end_time
        : '9:00';

    const indexInTimings = timings.findIndex(
      (item) => item === lastIndexEndTime
    );

    tempSpecificDate[index].timings.push({
      start_time: timings[indexInTimings + 1],
      end_time: timings[indexInTimings + 2],
    });
    setSpecificDate(tempSpecificDate);
  };

  const handleAddDate = () => {
    setSpecificDate((prevValue) => [
      ...prevValue,
      {
        date: '',
        timings: [],
      },
    ]);
  };

  const handleDeleteDate = (index) => {
    const tempSpecificDate = [...specificDate];
    tempSpecificDate.splice(index, 1);
    setSpecificDate(tempSpecificDate);
  };

  const handleSpecificTimeChange = (value, outerIndex, innerIndex, type) => {
    const tempSpecificDate = [...specificDate];

    if (type === 'end_time') {
      if (
        value <= tempSpecificDate[outerIndex].timings[innerIndex]['start_time']
      ) {
        tempSpecificDate[outerIndex].timings[innerIndex]['error'] =
          'Choose an end time later than the start time.';
        setHasError(true);
      } else if (
        tempSpecificDate[outerIndex].timings[innerIndex + 1] &&
        tempSpecificDate[outerIndex].timings[innerIndex + 1]['start_time'] <=
          value
      ) {
        tempSpecificDate[outerIndex].timings[innerIndex]['error'] =
          'Times overlap with another set of times.';
        setHasError(true);
      } else {
        tempSpecificDate[outerIndex].timings[innerIndex]['error'] = '';
        setHasError(false);
      }
    }

    if (type === 'start_time') {
      let errorFlag = false;
      if (innerIndex > 0) {
        if (
          value <=
          tempSpecificDate[outerIndex].timings[innerIndex - 1]['end_time']
        ) {
          tempSpecificDate[outerIndex].timings[innerIndex]['error'] =
            'Times overlap with another set of times.';
          setHasError(true);
          errorFlag = true;
        } else {
          tempSpecificDate[outerIndex].timings[innerIndex]['error'] = '';
          setHasError(false);
          errorFlag = false;
        }
      }
      if (!errorFlag) {
        if (
          value >= tempSpecificDate[outerIndex].timings[innerIndex]['end_time']
        ) {
          tempSpecificDate[outerIndex].timings[innerIndex]['error'] =
            'Choose an end time later than the start time.';
          setHasError(true);
        } else {
          tempSpecificDate[outerIndex].timings[innerIndex]['error'] = '';
          setHasError(false);
        }
      }
    }

    tempSpecificDate[outerIndex].timings[innerIndex][type] = value;
    setSpecificDate(tempSpecificDate);
  };

  const handleDeleteTimeSpecificDate = (outerIndex, innerIndex) => {
    const tempSpecificDate = [...specificDate];
    if (tempSpecificDate[outerIndex].timings.length === 1) {
      tempSpecificDate.splice(outerIndex, 1);
    } else {
      tempSpecificDate[outerIndex].timings.splice(innerIndex, 1);
    }
    setSpecificDate(tempSpecificDate);
  };

  return (
    <>
      {open ? (
        <div className="modal date-override fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-[40%] h-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content text-left">
              <div className="flex justify-end p-3">
                <div
                  className="modal-close cursor-pointer"
                  onClick={() => handleClose()}
                >
                  <svg
                    className="fill-current text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 18 18"
                  >
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                  </svg>
                </div>
              </div>
              <div className="items-center bg-indigo-50 border border-gray-200 m-2 p-3 shadow-md">
                <h2>Select the date(s) you want to assign specific hours</h2>
                {specificDate.map((dates, index) => {
                  return (
                    <>
                      <div
                        className="flex border bg-white border-b-0 p-2 relative  border-gray-200 mt-3 justify-between"
                        key={dates.date}
                        style={{ alignItems: 'center' }}
                      >
                        <div>
                          <input
                            type="date"
                            id="start"
                            className="date-input p-2 outline-none"
                            name="trip-start"
                            value={dates.date}
                            min={todaysDate}
                            onChange={(e) => handleSpecificDateChange(e, index)}
                          />
                        </div>
                        {dates.date !== '' && (
                          <div
                            className="cursor-pointer"
                            onClick={() => handleDeleteDate(index)}
                          >
                            <div className="bg-delete block delete-date">
                              <span className="hidden bg-gray-500 text-white p-3 absolute -top-8 right-0">
                                Delete Date
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="border bg-white border-gray-200 border-t-0 p-2 pb-4">
                        {' '}
                        {dates.date !== '' &&
                          dates.timings.map((time, index1) => {
                            return (
                              <div className="relative" key={index1}>
                                <div className="flex items-center pt-4 relative">
                                  <div className="w-40 relative z-0">
                                    <TimePicker
                                      use12Hours
                                      showSecond={false}
                                      focusOnOpen={true}
                                      format="hh:mm A"
                                      minuteStep={15}
                                      value={moment(time.start_time, [
                                        moment.ISO_8601,
                                        'HH:mm',
                                      ])}
                                      onChange={(e) =>
                                        handleSpecificTimeChange(
                                          e.format('HH:mm'),
                                          index,
                                          index1,
                                          'start_time'
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor="start-time"
                                      className="absolute duration-300 top-1 -z-1 origin-0 text-gray-500 px-4 left-0 text-xs"
                                    >
                                      Start Time
                                    </label>
                                  </div>
                                  <div className="px-4"> - </div>
                                  <div className="w-40 relative z-0">
                                    <span
                                      className="text-red-600 absolute -top-6"
                                      style={{ width: '20rem' }}
                                    >
                                      {time.error}
                                    </span>
                                    <TimePicker
                                      use12Hours
                                      showSecond={false}
                                      focusOnOpen={true}
                                      format="hh:mm A"
                                      minuteStep={15}
                                      value={moment(time.end_time, [
                                        moment.ISO_8601,
                                        'HH:mm',
                                      ])}
                                      onChange={(e) =>
                                        handleSpecificTimeChange(
                                          e.format('HH:mm'),
                                          index,
                                          index1,
                                          'end_time'
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor="end-time"
                                      className="absolute duration-300 top-1 -z-1 origin-0 text-gray-500 px-4 left-0 text-xs"
                                    >
                                      End Time
                                    </label>
                                  </div>
                                  <div
                                    className="pl-6 cursor-pointer"
                                    onClick={() =>
                                      handleDeleteTimeSpecificDate(
                                        index,
                                        index1
                                      )
                                    }
                                  >
                                    <span className="bg-delete block"></span>
                                  </div>
                                </div>
                                {index1 === 0 && (
                                  <div className="flex items-center absolute right-6 top-7">
                                    {dates.date !== '' && (
                                      <div
                                        className="px-4 cursor-pointer"
                                        onClick={() =>
                                          handleAddTimeSpecificDate(index)
                                        }
                                      >
                                        <span className="text-3xl">+</span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            );
                          })}{' '}
                      </div>
                    </>
                  );
                })}
                <div
                  className="cursor-pointer pt-4 pl-3"
                  onClick={() => handleAddDate()}
                >
                  <div className="flex items-center border border-blue-600 px-4 py-2 block text-center w-32 rounded-full">
                    <div className="text-l text-blue-600"> Add Date</div>{' '}
                    <div className="text-2xl pl-2 text-blue-600">+</div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    className={
                      'w-40 px-6 py-3 mt-5 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none focus:outline-none bg-red-900 hover:bg-black hover:shadow-lg'
                    }
                    onClick={() => handleClose(false)}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={
                      specificDate[0]?.date === '' ||
                      !specificDate.length ||
                      hasError
                    }
                    className={
                      'w-40 px-6 py-3 ml-4 mt-5 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none focus:outline-none ' +
                      (specificDate[0]?.date !== '' &&
                      specificDate.length &&
                      !hasError
                        ? 'bg-blue-900 hover:bg-yellow-500 hover:shadow-lg'
                        : 'bg-gray-400')
                    }
                    onClick={() => handleAddSpecificDates(specificDate)}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

DateOverridePopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  timings: PropTypes.array.isRequired,
  overrideData: PropTypes.array.isRequired,
  handleAddSpecificDates: PropTypes.func.isRequired,
};

export default DateOverridePopup;
