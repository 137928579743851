import PropTypes from 'prop-types';
import { convertTimeStampToDateWithTimeZone } from '../common/helper';
import { useState, useEffect } from 'react';

export const CalendarView = (props) => {
  const date = new Date();
  const [calendarDate, setCalendarDate] = useState(date);
  const [NoOfDays, setNoOfDays] = useState(0);
  const [StartDay, setStartDay] = useState(0);
  const [Month, setMonth] = useState(0);
  const [MonthNumber, setMonthNumber] = useState(0);
  const [Year, setYear] = useState(0);
  let check = [];

  const ChangeCalendar = (step) => {
    const todayDate = calendarDate;
    let month = '';
    if (step == 'minus') {
      month = todayDate.getMonth() - 1;
    } else {
      month = todayDate.getMonth() + 1;
    }

    const changeDate = calendarDate;
    changeDate.setMonth(month);
    setCalendarDate(changeDate);
    getCalendarDetails(changeDate);
  };

  const getCalendarDetails = (todayDate) => {
    const month = todayDate.getMonth();
    const year = todayDate.getFullYear();
    const noOfDays = new Date(year, month + 1, 0).getDate();
    const startDay = new Date(year, month, 1).getDay();
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    setNoOfDays(noOfDays);
    setStartDay(startDay);
    setMonth(monthNames[month]);
    setYear(year);
    setMonthNumber(month);
  };
  useEffect(() => {
    getCalendarDetails(calendarDate);
  }, []);

  return (
    <div className="flex items-center justify-center py-8 px-4">
      <div className="w-3/4 shadow-lg">
        <div className="md:p-8 p-5 bg-white rounded-t">
          <div className="px-4 flex items-center justify-between">
            <span
              tabIndex="0"
              className="focus:outline-none text-base font-bold text-black"
            >
              {Month} {Year}
            </span>
            <div className="flex items-center">
              <button
                aria-label="calendar backward"
                className="focus:text-gray-400 hover:text-gray-400 text-gray-800 dark:text-gray-100"
                onClick={() => {
                  ChangeCalendar('minus');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-left fill-black	"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="15 6 9 12 15 18" />
                </svg>
              </button>
              <button
                aria-label="calendar forward"
                className="focus:text-gray-400 hover:text-gray-400 ml-3 text-gray-800 dark:text-gray-100"
                onClick={() => {
                  ChangeCalendar('plus');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-right fill-black"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <polyline points="9 6 15 12 9 18" />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between pt-12 overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-black">
                        Su
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-black">
                        Mo
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-black">
                        Tu
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-black">
                        We
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-black">
                        Th
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-black">
                        Fr
                      </p>
                    </div>
                  </th>
                  <th>
                    <div className="w-full flex justify-center">
                      <p className="text-base font-medium text-center text-black">
                        Sa
                      </p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {[...Array(6)].map((elementInArray, index) => {
                  let x = index * 7;
                  //   let z = 0;
                  return (
                    <tr key={index}>
                      {[...Array(7)].map((value = x, index1) => {
                        let dayformat =
                          StartDay >= 0
                            ? index1 + 1 + value - StartDay
                            : StartDay - index1 + 1 + value - StartDay;
                        let y = ('0' + dayformat).slice(-2);
                        let mnum = ('0' + (MonthNumber + 1)).slice(-2);
                        let dformat = new Date(
                          Year + '-' + mnum + '-' + y
                        ).toLocaleDateString(props.countryCode);

                        const sessionCheck = props.scheduleData.filter(
                          (data) =>
                            convertTimeStampToDateWithTimeZone(
                              data.scheduled_timestamp,
                              props.coachTimeZone,
                              props.countryCode
                            ) == dformat
                        );

                        if (sessionCheck.length !== 0) {
                          check.push(dformat);
                        }

                        return y < NoOfDays + 1 ? (
                          y < 1 ? (
                            <td key={index1}></td>
                          ) : (
                            <td className="pt-6" key={index1}>
                              <div className="p-3 cursor-pointer flex justify-center">
                                <button
                                  onClick={
                                    check.includes(dformat) &&
                                    sessionCheck.length !== 0
                                      ? () =>
                                          props.setPopup({
                                            show: true,
                                            data: props.podData,
                                            date: dformat,
                                            sessions: sessionCheck,
                                          })
                                      : () =>
                                          props.setPopup({
                                            show: false,
                                            data: null,
                                            date: null,
                                            sessions: null,
                                          })
                                  }
                                  className={
                                    check.includes(dformat) &&
                                    sessionCheck.length !== 0
                                      ? 'focus:outline-none rounded-full focus:bg-yellow-500 hover:bg-yello-500 text-base p-3 font-medium text-white bg-blue-900 modal-open w-[60px] h-[60px]'
                                      : 'text-base text-black font-medium w-[60px] h-[60px]'
                                  }
                                >
                                  {y}
                                </button>
                              </div>
                            </td>
                          )
                        ) : (
                          <td key={index1}></td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

CalendarView.propTypes = {
  props: PropTypes.object,
  podData: PropTypes.array,
  scheduleData: PropTypes.any,
  setPopup: PropTypes.func,
  countryCode: PropTypes.string,
  coachTimeZone: PropTypes.string,
};
