import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { collection, where, query } from '@firebase/firestore';
import { useCore } from '../core/CoreContextProvider';
import { getDataByQuery } from '../common/Firebase';
import log from '../core/log';

function ViewPodScripts({ setPopup, popup }) {
  const { firestore } = useCore();
  const [scripts, setScripts] = useState([]);
  const [sessionData, setSessionData] = useState([]);

  const getScripts = async () => {
    try {
      const scriptRef = collection(firestore, 'scripts');
      let q = query(
        scriptRef,
        where('script_id', '==', popup?.data?.script_id)
      );
      const Allscripts = await getDataByQuery(q);
      setScripts(Allscripts);
      setSessionData(popup.data);
    } catch (error) {
      log('Firebase error: while getting scripts data', error);
    }
  };
  useEffect(() => {
    if (popup.data) getScripts();
  }, [popup.data]);

  return (
    <>
      {popup.show && sessionData.length !== 0 ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-5/6 mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div className="modal-close cursor-pointer">
                  <span
                    className="bg-close  block cursor-pointer"
                    onClick={() => setPopup({ show: false, data: null })}
                  ></span>
                </div>
              </div>
              <div className="p-5 p-5 border float-left pb-10 w-full">
                <div className="preview">
                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="session-id"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Session ID:
                    </label>
                    <div
                      id="session-id"
                      type="text"
                      className="mt-0 bg-transparent"
                    >
                      {sessionData.session_id}
                    </div>
                  </div>
                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="session-name"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Session Name:
                    </label>
                    <div
                      id="session-name"
                      type="text"
                      className="mt-0 bg-transparent"
                    >
                      {sessionData.session_name}
                    </div>
                  </div>
                  <div className="flex w-1/2 float-left mt-5">
                    <label
                      htmlFor="session-status"
                      className="text-black w-40 px-4 font-bold"
                    >
                      Status:
                    </label>
                    <div
                      name="select"
                      value=""
                      id="session-status"
                      className="mt-0 bg-transparent"
                    >
                      {sessionData.status}
                    </div>
                  </div>
                </div>
                <div className="mt-10 mb-5 float-left w-full text-center font-bold">
                  Added Scripts
                </div>
                <div className="w-full mx-auto my-0 text-left flex mt-5">
                  <div className="table-section w-full max-h-[250px] overflow-y-auto">
                    <table className="min-w-full border" id="table">
                      <thead className="">
                        <tr>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Script ID
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Topic
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Age Group
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Language
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                          >
                            Version
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {scripts.length !== 0 &&
                          scripts.map((data, index) => {
                            return (
                              <tr
                                key={btoa(index)}
                                className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                              >
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {data.script_id}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {data.topic}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {data.age_group}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {data.language}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {data.script_status}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {data.version}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

ViewPodScripts.propTypes = {
  setPopup: PropTypes.func.isRequired,
  popup: PropTypes.object.isRequired,
};

export default ViewPodScripts;
