export const Notification = () => {
  return (
    <div className="intro-x mr-3 sm:mr-4">
      <div
        className="cursor-pointer text-white"
        role="button"
        aria-expanded="false"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 26 26"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M18 8A6 6 0 006 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
          <path d="M13.73 21a2 2 0 01-3.46 0"></path>
        </svg>
      </div>
    </div>
  );
};
