import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DateOverridePopup from './DateOverridePopup';
import { useCore } from '../core/CoreContextProvider';
import { useAuth } from '../core/AuthContextProvider';
import {
  getData,
  getDataByQuery,
  updateFieldInDocument,
} from '../common/Firebase';
import LoadingSpinner from '../common/Loading';
import { collection, query, where } from '@firebase/firestore';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

export function ScheduleCoach() {
  const { firestore } = useCore();
  const { userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [overall, setOverall] = useState(null);
  const [errorOverall, setErrorOverall] = useState(null);
  const [assignedPod, setAssignedPod] = useState([]);
  const [coachDetails, setCoachDetails] = useState({});

  const [overrideData, setOverideData] = useState([]);
  const [checkboxState, setCheckboxState] = useState({
    SUN: false,
    MON: true,
    TUE: true,
    WED: true,
    THU: true,
    FRI: true,
    SAT: false,
  });

  const [showDatePopup, setShowDatePopup] = useState(false);
  const timings = [
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
  ];

  const handleCheckbox = (e) => {
    const { checked, name } = e.target;
    setCheckboxState({
      ...checkboxState,
      [name]: checked,
    });
    if (!checked) {
      setOverall({ ...overall, name: [] });
    } else {
      const tempOverall = { ...overall };
      tempOverall[name] = [
        {
          start_time: '09:00',
          end_time: '17:00',
        },
      ];
      setOverall(tempOverall);
      const sortedAvailability = {
        SUN: tempOverall['SUN'],
        MON: tempOverall['MON'],
        TUE: tempOverall['TUE'],
        WED: tempOverall['WED'],
        THU: tempOverall['THU'],
        FRI: tempOverall['FRI'],
        SAT: tempOverall['SAT'],
      };
      setErrorOverall(sortedAvailability);
    }
  };

  const handleAddTime = (day) => {
    const tempOverall = { ...overall };
    const lastIndexEndTime =
      tempOverall[day].length > 0
        ? tempOverall[day][tempOverall[day].length - 1].end_time
        : 0;
    const indexInTimings = timings.findIndex(
      (item) => item === lastIndexEndTime
    );
    if (indexInTimings === -1) {
      tempOverall[day].push({
        start_time: timings[0],
        end_time: timings[timings.length - 1],
      });
    } else {
      tempOverall[day].push({
        start_time: timings[indexInTimings + 1],
        end_time: timings[indexInTimings + 3] ?? timings[timings.length - 1],
      });
    }
    setOverall(tempOverall);

    const sortedAvailability = {
      SUN: tempOverall['SUN'],
      MON: tempOverall['MON'],
      TUE: tempOverall['TUE'],
      WED: tempOverall['WED'],
      THU: tempOverall['THU'],
      FRI: tempOverall['FRI'],
      SAT: tempOverall['SAT'],
    };
    setErrorOverall(sortedAvailability);
  };

  const handleTimeChange = (value, day, index, type) => {
    const scheduledPod = assignedPod.filter((pod) => pod.day === day);
    const tempOverall = JSON.parse(JSON.stringify(overall));
    const errorOverall = JSON.parse(JSON.stringify(overall));

    const currentIndexStartTime = tempOverall[day][index]['start_time'];
    const currentIndexEndTime = tempOverall[day][index]['end_time'];
    if (type === 'end_time') {
      let errorCounter = false;
      scheduledPod.forEach((pod) => {
        if (value < pod.end_time || value) {
          errorOverall[day][index]['error'] = 'A pod is already scheduled';
          errorOverall[day][index][type] = value;
          errorCounter = true;
        }
      });

      if (currentIndexStartTime >= value) {
        errorOverall[day][index]['error'] =
          'Choose an end time later than the start time.';
        errorOverall[day][index][type] = value;
      } else {
        if (
          tempOverall[day][index + 1] &&
          tempOverall[day][index + 1]['start_time'] <= value
        ) {
          errorOverall[day][index]['error'] =
            'Times overlap with another set of times.';
          errorOverall[day][index][type] = value;
        } else if (!errorCounter) {
          errorOverall[day][index]['error'] = '';
          errorOverall[day][index][type] = value;
          tempOverall[day][index][type] = value;
          setOverall(tempOverall);
        }
      }
    }

    if (type === 'start_time') {
      let errorCounter = false;

      // if (index === 0) {
      // scheduledPod.forEach((pod) => {
      //   if (value >= pod.start_time) {
      //     errorOverall[day][index]['error'] = 'A pod is already scheduled';
      //     errorOverall[day][index][type] = value;
      //     errorCounter = true;
      //   }
      // });
      // }

      if (index > 0) {
        if (value <= tempOverall[day][index - 1]['end_time']) {
          errorOverall[day][index]['error'] =
            'Times overlap with another set of times.';
          errorOverall[day][index][type] = value;
          errorCounter = true;
        }
      }
      if (!errorCounter) {
        if (currentIndexEndTime <= value) {
          errorOverall[day][index]['error'] =
            'Choose an end time later than the start time.';
          errorOverall[day][index][type] = value;
        } else {
          errorOverall[day][index]['error'] = '';
          errorOverall[day][index][type] = value;
          tempOverall[day][index][type] = value;
          setOverall(tempOverall);
        }
      }

      // scheduledPod.forEach((pod) => {
      //   if (value > pod.start_time) {
      //     errorOverall[day][index]['error'] = 'A pod is already scheduled';
      //     errorOverall[day][index][type] = value;
      //   }
      // });
    }

    const sortedAvailability = {
      SUN: errorOverall['SUN'],
      MON: errorOverall['MON'],
      TUE: errorOverall['TUE'],
      WED: errorOverall['WED'],
      THU: errorOverall['THU'],
      FRI: errorOverall['FRI'],
      SAT: errorOverall['SAT'],
    };
    setErrorOverall(sortedAvailability);
  };

  const handleDeleteTime = (day, index) => {
    const tempOverall = { ...overall };
    tempOverall[day].splice(index, 1);
    setOverall(tempOverall);

    const sortedAvailability = {
      SUN: tempOverall['SUN'],
      MON: tempOverall['MON'],
      TUE: tempOverall['TUE'],
      WED: tempOverall['WED'],
      THU: tempOverall['THU'],
      FRI: tempOverall['FRI'],
      SAT: tempOverall['SAT'],
    };
    setErrorOverall(sortedAvailability);

    if (!tempOverall[day].length) {
      setCheckboxState({
        ...checkboxState,
        [day]: false,
      });
    }
  };

  const handleAddOverride = async (overrideData) => {
    const filterOverrideData = overrideData.filter((data) => data.date !== '');
    setLoading(true);
    setShowDatePopup(false);
    setOverideData(filterOverrideData);

    const payload = {
      coach_id: userData.uid,
      specific_availability: filterOverrideData,
    };

    try {
      await updateFieldInDocument(
        firestore,
        'coach_schedule',
        userData.uid,
        payload
      );
      toast.success('Date override saved successfully');
    } catch (err) {
      console.error(`Error while scheduling coach availability: `, err.message);
    }
    setLoading(false);
  };

  const handleSaveSchedule = async () => {
    setLoading(true);
    const selectedOverall = {};
    for (let key in checkboxState) {
      if (checkboxState[key]) {
        selectedOverall[key] = overall[key];
      } else {
        selectedOverall[key] = [];
      }
    }
    const payload = {
      coach_id: userData.uid,
      overall_availability: selectedOverall,
    };

    try {
      await updateFieldInDocument(
        firestore,
        'coach_schedule',
        userData.uid,
        payload
      );
      toast.success('Changes saved successfully');
    } catch (err) {
      console.error(`Error while scheduling coach availability: `, err.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userData && overall) {
      handleSaveSchedule();
    }
  }, [overall, userData?.uid]);

  useEffect(() => {
    showDatePopup
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [showDatePopup]);

  useEffect(() => {
    if (userData) {
      setLoading(true);
      const getCoachSchedule = async () => {
        try {
          const coaches = await getData(
            firestore,
            userData.uid,
            'coach_schedule'
          );
          const coachRef = collection(firestore, 'coaches');
          const q = query(coachRef, where('uid', '==', userData.uid));
          const [coachDetails] = await getDataByQuery(q);

          setCoachDetails(coachDetails);
          if (coaches && Object.keys(coaches).length) {
            setOverall(coaches.overall_availability ?? {});

            if (Object.keys(coaches.overall_availability).length) {
              const sortedAvailability = {
                SUN: coaches.overall_availability['SUN'],
                MON: coaches.overall_availability['MON'],
                TUE: coaches.overall_availability['TUE'],
                WED: coaches.overall_availability['WED'],
                THU: coaches.overall_availability['THU'],
                FRI: coaches.overall_availability['FRI'],
                SAT: coaches.overall_availability['SAT'],
              };
              setErrorOverall(sortedAvailability);
              const tempCheckBoxTemp = {};
              Object.keys(coaches.overall_availability).forEach((days) => {
                if (coaches.overall_availability[days].length) {
                  tempCheckBoxTemp[days] = true;
                } else {
                  tempCheckBoxTemp[days] = false;
                }
              });
              setCheckboxState(tempCheckBoxTemp);
            }
            setOverideData(coaches.specific_availability ?? []);
            setAssignedPod(coaches.assigned_pod ?? []);
          } else {
            const overall = {
              SUN: [
                {
                  start_time: '09:00',
                  end_time: '17:00',
                },
              ],
              MON: [
                {
                  start_time: '09:00',
                  end_time: '17:00',
                },
              ],
              TUE: [
                {
                  start_time: '09:00',
                  end_time: '17:00',
                },
              ],
              WED: [
                {
                  start_time: '09:00',
                  end_time: '17:00',
                },
              ],
              THU: [
                {
                  start_time: '09:00',
                  end_time: '17:00',
                },
              ],
              FRI: [
                {
                  start_time: '09:00',
                  end_time: '17:00',
                },
              ],
              SAT: [
                {
                  startTime: '09:00',
                  end_time: '17:00',
                },
              ],
            };
            setOverall(overall);
            setErrorOverall(overall);
          }
        } catch (err) {
          console.error(`Error while fetching coache schedule: `, err.message);
        }
        setLoading(false);
      };

      getCoachSchedule();
    }
  }, [userData]);

  return (
    <div className="flex overflow-hidden">
      {loading && <LoadingSpinner />}
      <div className="content relative flex-1 px-6 pb-10 bg-indigo-50">
        <div className="intro-y flex items-center mt-6">
          <h2 className="text-2xl font-medium mr-auto">Coach Schedule</h2>
        </div>
        <div className="grid grid-cols-12 mt-5">
          <div className="col-span-12 lg:col-span-12">
            <div className="bg-white shadow-md rounded-md">
              <div className="flex flex-col sm:flex-row items-center p-5 border-b border-gray-300/60 dark:border-darkmode-400">
                <h2 className="font-bold text-base mr-auto">Coach Schedules</h2>
              </div>
              <div className="p-5">
                <div className="preview">
                  <div className="">
                    <div className="bottom-section flex justify-between">
                      <div className="border-gray-300 border-r w-3/4">
                        <div className="flex justify-between items-center">
                          <div className="font-bold mt-6">
                            Set your Weekly hours
                          </div>
                          <div className="mt-6 pr-8">
                            <label className="font-bold">Time Zone:</label>
                            <span className="pl-2">
                              {coachDetails?.time_zone}
                            </span>
                          </div>
                        </div>
                        <div>
                          {errorOverall &&
                            Object.keys(errorOverall).map((day) => {
                              let check = false;
                              return (
                                <div
                                  key={day}
                                  className="px-2 py-6 relative border-b"
                                >
                                  <div className="w-24 pt-7 inline-block absolute">
                                    <input
                                      type="checkbox"
                                      name={day}
                                      checked={checkboxState[day]}
                                      onChange={handleCheckbox}
                                    />
                                    <span className="pl-4 font-bold">
                                      {day}
                                    </span>
                                  </div>
                                  {checkboxState[day] ? (
                                    <div className="pl-28 inline-block mb-4">
                                      {' '}
                                      {errorOverall[day].map((time, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="flex items-center justify-center pt-4"
                                          >
                                            <div className="flex-auto w-40 relative z-0">
                                              <TimePicker
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                minuteStep={15}
                                                value={moment(time.start_time, [
                                                  moment.ISO_8601,
                                                  'HH:mm',
                                                ])}
                                                onChange={(e) =>
                                                  handleTimeChange(
                                                    e.format('HH:mm'),
                                                    day,
                                                    index,
                                                    'start_time'
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor="start-time"
                                                className="absolute duration-300 top-1 -z-1 origin-0 text-gray-500 px-4 left-0 text-xs"
                                              >
                                                Start Time
                                              </label>
                                            </div>

                                            <div className="px-4"> - </div>
                                            <div className="flex-auto w-40 relative z-0">
                                              <span
                                                className="text-red-600 absolute -top-6"
                                                style={{ width: '20rem' }}
                                              >
                                                {time.error}
                                              </span>
                                              <TimePicker
                                                placeholder="Select Time"
                                                use12Hours
                                                showSecond={false}
                                                focusOnOpen={true}
                                                format="hh:mm A"
                                                minuteStep={15}
                                                customInput="h-11"
                                                value={moment(time.end_time, [
                                                  moment.ISO_8601,
                                                  'HH:mm',
                                                ])}
                                                onChange={(e) =>
                                                  handleTimeChange(
                                                    e.format('HH:mm'),
                                                    day,
                                                    index,
                                                    'end_time'
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor="end-time"
                                                className="absolute duration-300 top-1 -z-1 origin-0 text-gray-500 px-4 left-0 text-xs"
                                              >
                                                End Time
                                              </label>
                                            </div>
                                            <div
                                              className="ml-6 p-2 cursor-pointer hover:bg-gray-200"
                                              onClick={() =>
                                                handleDeleteTime(day, index)
                                              }
                                            >
                                              <span className="bg-delete block"></span>
                                            </div>
                                          </div>
                                        );
                                      })}{' '}
                                    </div>
                                  ) : (
                                    <div className="inline-block">
                                      <div className="pl-28 pt-7 pb-6">
                                        Unavailable
                                      </div>
                                    </div>
                                  )}
                                  <div className="flex flex-col items-center absolute right-6 top-12">
                                    <div
                                      className="px-4 cursor-pointer hover:bg-gray-200"
                                      onClick={() => handleAddTime(day)}
                                    >
                                      <span className="text-3xl">+</span>
                                    </div>
                                  </div>
                                  <div className="flex items-center">
                                    {assignedPod.length
                                      ? assignedPod.map((pod) => {
                                          return (
                                            pod.day.length &&
                                            pod.day.map((podDay) => {
                                              if (podDay === day) {
                                                check = true;
                                              }
                                            })
                                          );
                                        })
                                      : ''}
                                    {check ? (
                                      <div className="mr-6">Pod Scheduled:</div>
                                    ) : (
                                      ''
                                    )}
                                    {assignedPod.length
                                      ? assignedPod.map((pod) => {
                                          return (
                                            pod.day.length &&
                                            pod.day.map((podDay) => {
                                              return (
                                                <>
                                                  {podDay === day && (
                                                    <div className="mr-2 p-2 rounded-full text-sm border-b bg-blue-900 text-white border-gray-200">
                                                      {pod.start_time} to{' '}
                                                      {pod.end_time}
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            })
                                          );
                                        })
                                      : null}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="w-1/4">
                        <div className="px-2 mt-6">
                          <div className="title">
                            <p className="font-bold">Add date overrides</p>
                          </div>
                          <div className="py-4 text-gray-500">
                            Add dates when your availability changes from your
                            weekly hours
                          </div>
                          <a
                            className="w-full border border-blue-600 px-4 py-2 block text-center modal-open rounded-full cursor-pointer"
                            onClick={() => setShowDatePopup(true)}
                          >
                            <span className="text-blue-600">
                              Add/Edit date overrides
                            </span>
                          </a>
                        </div>

                        {overrideData.length
                          ? overrideData.map((data) => {
                              return (
                                <div
                                  key={data.date}
                                  className="px-2 mt-6 flex justify-between border-b border-gray-200 pb-2"
                                >
                                  <div>
                                    {data.date} - {data.day}
                                  </div>
                                  <div>
                                    {data.timings.length
                                      ? data.timings.map((time) => {
                                          return (
                                            <div key={time.start_time}>
                                              {time.start_time} -{' '}
                                              {time.end_time}
                                            </div>
                                          );
                                        })
                                      : null}
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DateOverridePopup
        open={showDatePopup}
        timings={timings}
        handleClose={() => setShowDatePopup(false)}
        overrideData={overrideData}
        handleAddSpecificDates={(data) => handleAddOverride(data)}
      />
    </div>
  );
}
