import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../core/AuthContextProvider';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const { forgotpassword } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  async function onSubmit(data) {
    setLoading(true);
    try {
      await forgotpassword(data.email);
      toast.success(`An email has been send for password reset instructions.`);
    } catch (error) {
      setLoading(false);
      toast.error(
        `Problem in sending mail to ${data.email} . Please try again later.`
      );
    }
  }

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col min-h-screen bg-black px-10">
          <Link to="/" className="p-4 pt-12 hidden md:flex">
            <img alt="" className="w-56 h-20" src="./images/tapouts.png" />
          </Link>
          <div className="my-auto">
            <div className="text-white font-medium text-4xl leading-tight">
              A few more clicks to
              <br />
              sign in to your account.
            </div>
          </div>
        </div>
        <div className="my-auto mx-auto xl:ml-20  px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
          <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
            Forgot Your Password?
          </h2>
          {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            <div className="intro-x mt-8">
              <input
                className="form-control w-full border rounded-md shadow-md py-4 px-5 border border-black-300 mb-5 outline-none"
                placeholder="Email"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  },
                })}
              />
              {errors.email && <span>Enter a Valid Email-ID</span>}
              {/* include validation with required or other standard HTML validation rules */}
            </div>

            <div className="flex text-gray-700 justify-between dark:text-gray-600 text-xs sm:text-sm mt-6">
              <div className="button">
                <input
                  disabled={loading}
                  type="submit"
                  className="w-36 px-6 py-3 text-lg cursor-pointer text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                />
              </div>
              <div>
                <Link to="/" className="text-black hover:text-red-500 ml-3">
                  Already have an account? Login!
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
