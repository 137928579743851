import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { FeedBack } from './FeedBack';

export const Participants = ({
  details,
  session_doc_id,
  session_id,
  podId,
  FeedButtonToggle,
  getParticipants,
  setLoading,
  setDetails,
}) => {
  const [showFeedBack, setshowFeedBack] = useState(false);
  useEffect(() => {
    showFeedBack
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'visible');
  }, [showFeedBack]);

  return (
    <>
      <tr>
        {details.length !== 0 && (
          <td colSpan="7">
            <div className="w-3/4 mx-auto mt-10">
              <div className="mt-10">
                <button
                  onClick={() => {
                    setshowFeedBack(true);
                  }}
                  className="w-52 modal-open px-6 float-right mb-5 py-3 ml-4 text-lg text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                >
                  {FeedButtonToggle ? 'Edit Feedback' : 'Add Feedback'}
                </button>
                <table className="mt-5 w-full mb-11">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                      >
                        Children ID
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                      >
                        Children Name
                      </th>
                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                      >
                        Present
                      </th>

                      <th
                        scope="col"
                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                      >
                        Feeedaback
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {details.map((child) => {
                      return (
                        <tr
                          key={child.id}
                          className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                            {child.children_id}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                            {`${child.first_name} ${child.last_name}`}
                          </td>
                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                            {child.present ? 'Yes' : '-'}
                          </td>

                          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
                            {child.feedback ? child.feedback : '-'}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        )}
      </tr>

      {showFeedBack && (
        <FeedBack
          participants={details}
          setshowFeedBack={setshowFeedBack}
          session_doc_id={session_doc_id}
          session_id={session_id}
          podId={podId}
          getParticipants={getParticipants}
          setLoading={setLoading}
          setDetails={setDetails}
        />
      )}
    </>
  );
};

Participants.propTypes = {
  details: PropTypes.array.isRequired,
  session_doc_id: PropTypes.string.isRequired,
  session_id: PropTypes.string.isRequired,
  podId: PropTypes.string.isRequired,
  FeedButtonToggle: PropTypes.bool,
  getParticipants: PropTypes.func,
  setLoading: PropTypes.func,
  setDetails: PropTypes.func,
};
