import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { addDocToHistory, endFunc, isToday } from '../common/helper';
import { useCore } from '../core/CoreContextProvider';
import { useState } from 'react';
import { updateFieldInDocument } from '../common/Firebase';
import log from '../core/log';
import LoadingSpinner from '../common/Loading';
import { toast } from 'react-toastify';
import { where, collection, query } from 'firebase/firestore';
import { getDataByQuery } from '../common/Firebase';

export const PodSessions = ({
  podData,
  getSessionData,
  popupFunction,
  coachDetails,
}) => {
  const { firestore } = useCore();
  let status = null;
  const [loading, setLoading] = useState(false);
  const [podList, setPodList] = useState({});
  const [scheduledStatus, setScheduledStatus] = useState([]);

  const realTimeStatus = (podData) => {
    const FIVE_MIN = 5 * 60 * 1000;
    Object.keys(podData).map((pod_id) => {
      podData[pod_id].map((data) => {
        getScheduleStatus(data);
        const today = new Date();
        const startDates = data.scheduled_timestamp.toDate();
        const endDate = data.scheduled_timestamp.toDate();
        endDate.setMinutes(endDate.getMinutes() + Number(data.duration));
        if (
          startDates.getTime() - today.getTime() >= FIVE_MIN ||
          endDate.getTime() - today.getTime() >= FIVE_MIN
        ) {
          let givenTime;
          if (data.status === 'scheduled') {
            givenTime = startDates.getTime() - FIVE_MIN;
          } else if (data.status === 'inprogress') {
            givenTime = endDate.getTime() - FIVE_MIN;
          }
          const currTime = today.getTime();
          let milliseconds = givenTime - currTime;
          setTimeout(() => {
            setPodList({ ...podData });
            getScheduleStatus(data);
          }, milliseconds);
        }
      });
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    setPodList({ ...podData });
    realTimeStatus(podData);
  }, [podData]);

  const findStartAndComp = (date, endTime, id, schedule) => {
    const data = isToday(date, endTime);
    if (data === 'completed') {
      updateField('completed', id);
      addToHistory(schedule);
    }
    return data;
  };

  const findEnd = (date, endTime, id, schedule) => {
    const data = endFunc(date, endTime);
    if (data === 'completed') {
      updateField('completed', id);
      addToHistory(schedule);
    }
    return data;
  };
  const updateField = async (status, docId) => {
    setLoading(true);
    try {
      await updateFieldInDocument(firestore, `live_session_schedule`, docId, {
        status: status,
      });
    } catch (error) {
      log('Firebase error: while updating schedule status', error);
    }
    getSessionData(coachDetails);
  };

  const addToHistory = async (schedule) => {
    setLoading(true);
    try {
      const sessionHistoryRef = collection(firestore, 'live_session_history');
      let q = query(
        sessionHistoryRef,
        where('session_id', '==', schedule.session_id),
        where('pod_id', '==', schedule.pod_id)
      );
      const sessionHistoryData = await getDataByQuery(q);
      if (!sessionHistoryData.length) {
        await addDocToHistory(firestore, schedule);
      }
      toast.success(
        `Session has been completed, you can check in pod session history at
           ${schedule.pod_id}`
      );
    } catch (error) {
      log('Firebase error: while adding history ', error);
    }

    getSessionData(coachDetails);
  };

  const btnClick = (value, schedule) => {
    if (value === 'Start') {
      updateField('inprogress', schedule.id);
    } else if (value === 'End') {
      updateField('completed', schedule.id);
      addToHistory(schedule);
    }
  };

  const getScheduleStatus = (schedule) => {
    let data;
    schedule.end_time = schedule.scheduled_timestamp.toDate();
    schedule.end_time.setMinutes(
      schedule.end_time.getMinutes() + Number(schedule.duration)
    );
    if (schedule.status === 'scheduled') {
      data = findStartAndComp(
        schedule.scheduled_timestamp,
        schedule.end_time,
        schedule.id,
        schedule
      );
    } else if (schedule.status === 'inprogress') {
      data = findEnd(
        schedule.scheduled_timestamp,
        schedule.end_time,
        schedule.id,
        schedule
      );
    }

    if (scheduledStatus.length !== 0) {
      scheduledStatus.find((e) => {
        e[schedule.id] = data;
      });
      setScheduledStatus(scheduledStatus);
    } else {
      const obj = { [schedule.id]: data };
      scheduledStatus.push(obj);
      setScheduledStatus(scheduledStatus);
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <div>
        <table className="min-w-full border" id="table">
          <thead className="">
            <tr>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
              >
                Pod Id
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
              >
                Session Name
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
              >
                Date
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
              >
                Time
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
              >
                Coach
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
              >
                Status
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
              >
                Action
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-center uppercase bg-indigo-50 border-b"
              >
                Zoom Url
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(podList).map((pod_id) => {
              return podList[pod_id].map((data, index) =>
                index === 0 ? (
                  <tr
                    key={pod_id}
                    className="bg-white border-b transition duration-300 ease-in-out "
                  >
                    <td
                      className="text-sm text-gray-900  px-6 py-4 whitespace-nowrap cursor-pointer font-bold"
                      rowSpan={podList[pod_id].length}
                    >
                      {pod_id}
                    </td>
                    <td
                      className="text-sm text-blue-900 font-light px-6 py-4 whitespace-nowrap border-l cursor-pointer underline"
                      rowSpan="1"
                      onClick={() => {
                        popupFunction(data);
                      }}
                    >
                      {data.session_name}
                    </td>
                    <td
                      className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                      rowSpan="1"
                    >
                      {data.start_date}
                    </td>
                    <td
                      className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                      rowSpan="1"
                    >
                      {data.start_time}
                    </td>
                    <td
                      className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                      rowSpan="1"
                    >
                      {data.coach_name}
                    </td>
                    <td
                      className={`text-sm ${
                        data.status === 'inprogress' ? ' text-green-500' : ''
                      }  font-light px-6 py-4 whitespace-nowrap`}
                    >
                      {data.status}
                    </td>

                    <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap ">
                      <div className="flex items-center">
                        {scheduledStatus.find((e) => {
                          if (e[data.id] !== undefined) {
                            status = e[data.id];
                            return true;
                          }
                        }) ? (
                          <button
                            onClick={() => {
                              btnClick(status, data);
                            }}
                            className={`${
                              status == 'Start'
                                ? 'bg-blue-900'
                                : status == 'completed'
                                ? 'bg-green-600'
                                : 'bg-red-600'
                            } text-white font-bold py-2 px-4 rounded-full font-bold py-2 px-4 rounded`}
                          >
                            {status}
                          </button>
                        ) : (
                          <span className="text-gray-900 font-light px-6 whitespace-nowrap  text-4xl">
                            -
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-blue-500 underline hover:text-blue-700 cursor-pointer">
                      <a href={data.start_url} target="_blank" rel="noreferrer">
                        {data.start_url}
                      </a>
                    </td>
                  </tr>
                ) : (
                  <tr
                    key={data.id}
                    className="bg-white border-b transition duration-300 ease-in-out "
                  >
                    <td
                      className="text-sm text-blue-900 font-light px-6 py-4 whitespace-nowrap border-l cursor-pointer underline"
                      rowSpan="1"
                      onClick={() => {
                        popupFunction(data);
                      }}
                    >
                      {data.session_name}
                    </td>
                    <td
                      className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap "
                      rowSpan="1"
                    >
                      {data.start_date}
                    </td>
                    <td
                      className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                      rowSpan="1"
                    >
                      {data.start_time}
                    </td>
                    <td
                      className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                      rowSpan="1"
                    >
                      {data.coach_name}
                    </td>
                    <td
                      className={`text-sm ${
                        data.status === 'inprogress' ? ' text-green-500' : ''
                      }  font-light px-6 py-4 whitespace-nowrap`}
                    >
                      {data.status}
                    </td>
                    <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap ">
                      <div className="flex items-center">
                        {scheduledStatus.find((e) => {
                          if (e[data.id] !== undefined) {
                            status = e[data.id];
                            return true;
                          }
                        }) ? (
                          <button
                            onClick={() => {
                              btnClick(status, data);
                            }}
                            className={`${
                              status == 'Start'
                                ? 'bg-blue-900'
                                : status == 'completed'
                                ? 'bg-green-600'
                                : 'bg-red-600'
                            } text-white font-bold py-2 px-4 rounded-full font-bold py-2 px-4 rounded cursor-pointer`}
                          >
                            {status}
                          </button>
                        ) : (
                          <span className="text-gray-900 font-light px-6 whitespace-nowrap  text-4xl">
                            -
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-blue-500 underline hover:text-blue-700 cursor-pointer">
                      <a href={data.start_url} target="_blank" rel="noreferrer">
                        {data.start_url}
                      </a>
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

PodSessions.propTypes = {
  podData: PropTypes.object.isRequired,
  getSessionData: PropTypes.func,
  popupFunction: PropTypes.func,
  coachDetails: PropTypes.any,
};
