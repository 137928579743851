import React from 'react';
import SubMenuItem from './SubMenuItem';
import PropTypes from 'prop-types';

const SubMenu = ({ id, subMenu }) => {
  // const subMenuItemStyle = "flex items-start p-10 -m-5 w-6 max-w-sm space-x-3 transition duration-150 ease-in-out rounded-lg pb-7 group";

  return (
    <div id={id} className="hidden shadow-lg rounded-xl w-60">
      <ul className="overflow-hidden border border-gray-100">
        {subMenu.map((item) => {
          return (
            <SubMenuItem
              label={item.label}
              target={item.target}
              id={item.id}
              key={btoa(item.label)}
            />
          );
        })}
      </ul>
    </div>
  );
};

SubMenu.propTypes = {
  id: PropTypes.string.isRequired,
  subMenu: PropTypes.array.isRequired,
};

export default SubMenu;
