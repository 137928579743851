import React, { useEffect, useState } from 'react';
import { useCore } from '../core/CoreContextProvider';
import ViewPodScripts from '../Pods/ViewPodScripts';
import { findUpcomingSessions, getDataWithId } from '../common/helper';
import LoadingSpinner from '../common/Loading';
import log from '../core/log';
import { useAuth } from '../core/AuthContextProvider';
import { PodSessions } from './PodSessions';
import { getDataByQuery } from '../common/Firebase';
import { where, collection, query } from 'firebase/firestore';

export const UpcomingSessions = () => {
  const { userData } = useAuth();
  const [podList, setPodList] = useState({});
  const [scheduleSessions, setScheduleSessions] = useState([]);
  const [coachDetails, setCoachDetails] = useState([]);
  const [popup, setPopup] = useState({ show: false, data: null });
  const [loading, setLoading] = useState(true);
  const { firestore } = useCore();

  const getSessionData = async (coachDetails) => {
    try {
      const liveSchedule = await findUpcomingSessions(
        firestore,
        userData,
        coachDetails
      );
      alignSession(liveSchedule);
      setLoading(false);
    } catch (error) {
      log('Firebase error: while getting pod data', error);
    }
  };

  useEffect(() => {
    getCoachDetails();
  }, []);

  const getCoachDetails = async () => {
    try {
      const coachRef = collection(firestore, 'coaches');
      const q = query(coachRef, where('uid', '==', userData.uid));
      const [coachDetails] = await getDataByQuery(q);
      getSessionData(coachDetails);
      setCoachDetails(coachDetails);
    } catch (err) {
      log('Error while getting coach data' + err);
    }
  };

  function isDateInThisWeek(date) {
    const todayObj = new Date();
    const todayDate = todayObj.getDate();

    // get last date of week
    const afterSevenDay = new Date(todayObj.setDate(todayDate + 7));

    // if date is equal or within the first and last dates of the week
    return date <= afterSevenDay;
  }

  const getCoachName = async (scheduleArr) => {
    try {
      await Promise.all(
        scheduleArr.map(async (schedule) => {
          const name = await getDataWithId(
            firestore,
            schedule.coach_uid,
            'coaches'
          );
          schedule.coach_name = name;
        })
      );
    } catch (error) {
      log('Error in getting coach name');
    }

    setScheduleSessions(scheduleArr);
  };

  const alignSession = async (scheduleData) => {
    let scheduleArr = [];
    let podList = {};

    if (scheduleData?.length) {
      scheduleData.map((session) => {
        const date = new Date(session.start_date);
        isDateInThisWeek(date) && scheduleArr.push(session);
      });

      scheduleArr.sort(function (a, b) {
        const dateA = new Date(`${a.start_date} ${a.start_time}`),
          dateB = new Date(`${b.start_date} ${b.start_time}`);
        return dateA - dateB;
      });

      scheduleArr.map((session) => {
        if (Object.keys(podList).includes(session.pod_id)) {
          podList[session.pod_id].push(session);
        } else {
          podList[session.pod_id] = [];
          podList[session.pod_id].push(session);
        }
      });
      await Promise.all(
        Object.keys(podList).map(async (pod_id) => {
          const podRef = collection(firestore, 'pods');
          const q = query(podRef, where('pod_id', '==', pod_id));
          const podDetails = await getDataByQuery(q);
          for (let i = 0; i < podList[pod_id].length; i++) {
            podList[pod_id][i].start_url = podDetails[0]?.start_url ?? '';
          }
        })
      );
      setPodList(podList);
      getCoachName(scheduleArr);
    } else {
      setScheduleSessions([]);
    }
  };

  const popupFunction = (data) => {
    setPopup({
      show: true,
      data: data,
    });
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && scheduleSessions.length !== 0 ? (
        <div className="overflow-hidden">
          <div className="content relative min-h-screen flex-1 px-6 pt-4 pb-10 bg-indigo-50">
            <div className="intro-y flex items-center mt-6">
              <h2 className="text-2xl font-medium mr-auto">
                Upcoming Sessions in Current week
              </h2>
            </div>
            <div className="grid grid-cols-12 gap-6 mt-5">
              <div className="col-span-12 lg:col-span-12">
                <div className="bg-white shadow-md rounded-md mt-5">
                  <div id="input" className="p-5">
                    <div className="preview">
                      {scheduleSessions.length !== 0 && (
                        <div id="third" className="p-5">
                          <div className="w-full mx-auto my-0 text-left flex mt-5">
                            <div className="table-section w-full overflow-scroll">
                              <PodSessions
                                podData={podList}
                                getSessionData={getSessionData}
                                popupFunction={popupFunction}
                                coachDetails={coachDetails}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ViewPodScripts setPopup={setPopup} popup={popup} />
        </div>
      ) : (
        !loading &&
        scheduleSessions.length === 0 && (
          <span>No Sessions are found in current week</span>
        )
      )}
    </>
  );
};
