import React, { useEffect, useState } from 'react';
import { useCore } from '../core/CoreContextProvider';
import { getDataByQuery } from '../common/Firebase';
import { where, collection, query } from 'firebase/firestore';
import LoadingSpinner from '../common/Loading';
import ViewSchedule from './ViewSchedule';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../core/AuthContextProvider';
import log from '../core/log';
import { CalendarView } from './CalendarView';
import { getCountryCode } from '../common/helper';

export const CoachPods = () => {
  const [podData, setPodData] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState('first');
  const [countryCode, setCountryCode] = useState('en-US');
  const [coachTimeZone, setCoachTimeZone] = useState('America/New_York');

  const [popup, setPopup] = useState({
    show: false,
    data: null,
    date: null,
    sessions: null,
  });

  const { firestore } = useCore();
  const { userData } = useAuth();

  const getPodData = async (coachDetails) => {
    const podRef = collection(firestore, 'pods');
    const q1 = query(podRef, where(userData.role + '_uid', '==', userData.uid));
    try {
      const podDetails = await getDataByQuery(q1);
      let result = [];
      const countryCode = await getCountryCode(coachDetails.country);
      await Promise.all(
        podDetails.map(async (pod) => {
          const sessionRef = collection(firestore, 'live_session_schedule');
          const q1 = query(
            sessionRef,
            where(userData.role + '_uid', '==', userData.uid),
            where('pod_doc_id', '==', pod.id)
          );
          const sessionDetails = await getDataByQuery(q1);
          if (sessionDetails.length) {
            result = [...sessionDetails, ...result];
          }
        })
      );
      setCountryCode(countryCode);
      setPodData(podDetails);
      setScheduleData(result);
      setCoachTimeZone(coachDetails.time_zone);
      setLoading(false);
    } catch (error) {
      log('Firebase error: while getting pod and session  data', error);
    }
  };

  useEffect(() => {
    getCoachDetails();
  }, []);
  const getCoachDetails = async () => {
    try {
      const coachRef = collection(firestore, 'coaches');
      const q = query(coachRef, where('uid', '==', userData.uid));
      const [coachDetails] = await getDataByQuery(q);
      getPodData(coachDetails);
    } catch (err) {
      log('Error while getting coach data' + err);
    }
  };

  const tabHandler = (index) => {
    setTabValue(index);
  };

  return (
    <div className="content relative flex-1 px-6 pt-4 pb-10 bg-indigo-50">
      <div className="intro-y flex items-center mt-6">
        <h2 className="text-2xl font-medium mr-auto">Coach Schedule</h2>
      </div>
      {loading && <LoadingSpinner />}
      {!loading && podData.length !== 0 && (
        <div className="grid grid-cols-12 gap-6 mt-5">
          <div className="col-span-12 lg:col-span-12">
            <div className="bg-white shadow-md rounded-md">
              <div className="p-5">
                <div className="tabs-section">
                  <ul id="tabs" className="inline-flex pt-2 w-full border-b">
                    <li
                      className={
                        tabValue == 'first'
                          ? 'bg-white px-5 text-gray-800 font-semibold py-3 rounded-t border-t border-r border-l -mb-px'
                          : 'px-5 text-gray-800 font-semibold py-3 rounded-t'
                      }
                    >
                      <button
                        id="default-tab"
                        onClick={() => tabHandler('first')}
                      >
                        List View
                      </button>
                    </li>
                    <li
                      className={
                        tabValue == 'second'
                          ? 'bg-white px-5 text-gray-800 font-semibold py-3 rounded-t border-t border-r border-l -mb-px'
                          : ' px-5 text-gray-800 font-semibold py-3 rounded-t'
                      }
                    >
                      <button onClick={() => tabHandler('second')}>
                        Calendar View
                      </button>
                    </li>
                  </ul>
                  <div id="tab-contents" className="border border-t-0">
                    <div
                      id="first"
                      className="p-5"
                      style={{ display: tabValue == 'first' ? '' : 'none' }}
                    >
                      <div className="w-full mx-auto text-left flex mt-5">
                        <div className="table-section w-full">
                          <table className="min-w-full border" id="table">
                            <thead className="uppercase bg-indigo-50 border-b">
                              <tr>
                                <th
                                  data-type="number"
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left"
                                >
                                  Pod ID
                                </th>
                                <th
                                  data-type="number"
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left"
                                >
                                  Course Name
                                </th>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left"
                                >
                                  Age Group
                                </th>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left"
                                >
                                  Language
                                </th>
                                <th
                                  scope="col"
                                  className="font-medium text-gray-900 px-6 py-4 text-left"
                                >
                                  Start Date
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {podData.length !== 0 &&
                                podData.map((data, index) => {
                                  return (
                                    <tr
                                      key={index}
                                      className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                    >
                                      <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap text-blue-500 underline hover:text-blue-700 cursor-pointer">
                                        <NavLink
                                          to="/coach/pod/sessions"
                                          state={{ id: data.id }}
                                        >
                                          {data.pod_id}
                                        </NavLink>
                                      </td>

                                      <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {data.course_name}
                                      </td>
                                      <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {data.age_group}
                                      </td>
                                      <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {data.language}
                                      </td>
                                      <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                        {data.start_date}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div
                      id="second"
                      style={{ display: tabValue == 'second' ? '' : 'none' }}
                    >
                      <CalendarView
                        podData={podData}
                        setPopup={setPopup}
                        scheduleData={scheduleData}
                        countryCode={countryCode}
                        coachTimeZone={coachTimeZone}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && podData.length === 0 && <span>No Pods are found</span>}
      {popup.show && (
        <ViewSchedule
          setPopup={setPopup}
          popup={popup}
          countryCode={countryCode}
        />
      )}
    </div>
  );
};
