import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { convertTimeStamp } from '../common/helper';

function ViewSchedule({ setPopup, popup, countryCode }) {
  const [check, setCheck] = useState([]);
  const pods = popup.data;
  const sessions = popup.sessions;

  const setData = () => {
    const podIds = {};
    const result = [];
    pods.forEach((e) => (podIds[e.id] = e));

    sessions.forEach((key) => {
      if (podIds[key.pod_doc_id]) {
        let obj = { ...podIds[key.pod_doc_id] };
        obj.session_name = key.session_name;
        obj.session_status = key.status;
        obj.start_time = convertTimeStamp(
          key?.scheduled_timestamp,
          key?.time_zone,
          countryCode
        );
        result.push(obj);
      }
    });

    setCheck(result);
  };

  useEffect(() => {
    setData();
  }, []);

  return (
    <div>
      {popup.show && check.length !== 0 ? (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
          <div className="modal-overlay  fixed w-full h-full overflow-y-scroll bg-gray-900 left-0 right-0 opacity-50 -z-10"></div>
          <div className="modal-container bg-white w-2/3 min-h-[50%] mx-auto rounded shadow-lg overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-end pb-3">
                <div className="modal-close cursor-pointer ">
                  <span
                    className="bg-close block cursor-pointer"
                    onClick={() =>
                      setPopup({ show: false, data: null, date: null })
                    }
                  ></span>
                </div>
              </div>

              <div className="p-5 h-96 float-left w-full pb-24 overflow-y-auto">
                <div className="preview ">
                  {check.map((data, index) => {
                    return (
                      <div className="mt-5" key={index}>
                        <div className="flex mt-4">
                          <div className="label font-bold w-40">Pod ID:</div>
                          <div className="value">{data.pod_id}</div>
                        </div>
                        <div className="flex mt-4">
                          <div className="label font-bold w-40">
                            Course Name:
                          </div>
                          <div className="value">{data.course_name}</div>
                        </div>
                        <div className="flex mt-4">
                          <div className="label font-bold w-40">Language:</div>
                          <div className="value">{data.language}</div>
                        </div>
                        <div className="flex mt-4">
                          <div className="label font-bold w-40">Age Group:</div>
                          <div className="value">{data.age_group}</div>
                        </div>
                        <div className="flex mt-4">
                          <div className="label font-bold w-40">
                            Meeting link:
                          </div>
                          <div className="value text-blue-500 underline hover:text-blue-700 cursor-pointer">
                            <a
                              href={data.start_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span>{data.start_url}</span>
                            </a>
                          </div>
                        </div>
                        <div className="flex mt-4">
                          <div className="label font-bold w-40">Start Time</div>
                          <div className="value">{data.start_time}</div>
                        </div>

                        <div className="flex mt-4">
                          <div className="label font-bold w-40">
                            Session Name
                          </div>
                          <div className="value">{data.session_name}</div>
                        </div>
                        <div className="flex mt-4">
                          <div className="label font-bold w-40">
                            Session Status
                          </div>
                          <div className="value">{data.session_status}</div>
                        </div>
                        <br></br>
                        <hr />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

ViewSchedule.propTypes = {
  setPopup: PropTypes.func.isRequired,
  popup: PropTypes.object.isRequired,
  countryCode: PropTypes.string,
};

export default ViewSchedule;
