import { collection, query, where } from 'firebase/firestore';
import { getDataByQuery, addDocument } from '../common/Firebase';
import { Timestamp } from '@firebase/firestore';
import log from '../core/log';
import { lookup } from 'country-data-list';

export const getCountryCode = async (name) => {
  try {
    const countryData = lookup.countries({
      name: name.toLowerCase(),
    })[0];
    const lang = countryData.languages.includes('eng')
      ? 'en'
      : countryData.languages[0].slice(0, 2);
    const code = lang + '-' + countryData.alpha2;

    return code;
  } catch (err) {
    console.log('Error in adding locale' + err.message);
  }
};

export const findUpcomingSessions = async (
  db,
  userData,
  coachDetails,
  pod_id = null
) => {
  let scheduleArr = [];

  const scheduleRef = collection(db, 'live_session_schedule');
  let role = userData.role ?? 'coach';
  let q = pod_id
    ? query(
        scheduleRef,
        where(role + '_uid', '==', userData.uid),
        where('pod_doc_id', '==', pod_id),
        where('status', '!=', 'completed')
      )
    : query(
        scheduleRef,
        where(role + '_uid', '==', userData.uid),
        where('status', '!=', 'completed')
      );
  try {
    const locale = await getCountryCode(coachDetails.country);
    let scheduleData = await getDataByQuery(q);
    scheduleArr = scheduleData.map((updatedData) => {
      updatedData.start_time = convertTimeStamp(
        updatedData.scheduled_timestamp,
        coachDetails.time_zone,
        locale
      );
      updatedData.start_date = convertTimeStampToDate(
        updatedData.scheduled_timestamp,
        locale
      );

      return updatedData;
    });

    return scheduleArr;
  } catch (err) {
    log('Firebase error: while getting upcomming session details', err);
  }
};

export const findHistorySessions = async (
  db,
  userData,
  pod_id,
  coachDetails
) => {
  let historyArr = [];
  const historyRef = collection(db, 'live_session_history');
  let role = userData.role ?? 'coach';
  let q = query(
    historyRef,
    where(role + '_uid', '==', userData.uid),
    where('pod_doc_id', '==', pod_id)
  );

  try {
    const locale = await getCountryCode(coachDetails.country);
    let historyData = await getDataByQuery(q);
    historyData.forEach((data) => {
      let updatedData = { ...data };
      updatedData.start_time = convertTimeStamp(
        data.scheduled_timestamp,
        coachDetails.time_zone,
        locale
      );

      updatedData.completed_date = convertTimeStampToDate(
        data.completed_date,
        locale
      );
      historyArr.push(updatedData);
    });
    return historyArr;
  } catch (err) {
    log('Firebase error: while getting history session details', err);
  }
};

export const getDataWithId = async (firestore, id, path) => {
  if (id) {
    try {
      const coachRef = collection(firestore, path);
      const q = query(coachRef, where('uid', '==', id));
      const [coachDetails] = await getDataByQuery(q);
      if (!coachDetails) throw new Error('Query Error');
      return coachDetails?.first_name;
    } catch (error) {
      log('Firebase error: while getting coach details', error);
      return false;
    }
  }
};

export const addDocToHistory = async (firestore, schedule) => {
  const obj = { ...schedule };

  obj.completed_date = Timestamp.fromDate(
    new Date(obj.scheduled_timestamp.toDate().getTime() + obj.duration * 60000)
  );
  obj.status = 'completed';
  delete obj.id;
  try {
    await addDocument('live_session_history', firestore, obj);
  } catch (error) {
    log('Firebase error: while adding history data', error);
  }
};

export const isToday = (date, endTime) => {
  const FIVE_MIN = 5 * 60 * 1000;
  const today = new Date();
  date = date.toDate();
  const dates = new Date(date);

  if (today.toDateString() === dates.toDateString()) {
    const startDate = date;
    if (
      (today.getTime() < startDate.getTime() &&
        startDate.getTime() - today.getTime() <= FIVE_MIN) ||
      (startDate.getHours() == today.getHours() &&
        startDate.getMinutes() == today.getMinutes()) ||
      (startDate.getTime() < today.getTime() &&
        endTime.getTime() > today.getTime())
    ) {
      return 'Start';
    } else if (endTime.getTime() < today.getTime()) {
      return 'completed';
    }
  } else if (today > dates) {
    return 'completed';
  }
};

export const endFunc = (date, endTime) => {
  const FIVE_MIN = 5 * 60 * 1000;
  const today = new Date();
  date = date.toDate();
  const dates = new Date(date);

  if (String(endTime.getTime() - today.getTime()).includes('-')) {
    return 'completed';
  }
  if (endTime.getTime() - today.getTime() <= FIVE_MIN) {
    return 'End';
  } else if (
    endTime.getTime() < today.getTime() ||
    today.toDateString() > dates.toDateString()
  ) {
    return 'completed';
  }
};

export const convertTimeStamp = (time, timezone, locale) => {
  const changedDate = time.toDate().toLocaleString(locale, {
    timeZone: timezone,
  });

  const atTime = new Date(changedDate).toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
  });
  return atTime;
};

export const convertTimeStampToDate = (timestamp, locale) => {
  return new Date(timestamp.toDate()).toLocaleDateString(locale);
};

export const convertEndTime = (timestamp, duration, timezone, locale) => {
  let end_time = timestamp.toDate();
  return convertTimeStamp(
    Timestamp.fromDate(new Date(end_time.getTime() + duration * 60000)),
    timezone,
    locale
  );
};

export const convertTimeStampToDateWithTimeZone = (time, timezone, locale) => {
  const changedDate = time.toDate().toLocaleString(locale, {
    timeZone: timezone,
  });

  const date = new Date(changedDate).toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return date;
};
