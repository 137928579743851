import { useAuth } from '../../core/AuthContextProvider';
const CoachDashBoard = () => {
  const { userData } = useAuth();
  return (
    <div>
      {' '}
      Hi, You are logged in as {userData.role} {userData.email}{' '}
    </div>
  );
};

export default CoachDashBoard;
