import { updateFieldInDocument } from '../../common/Firebase';
import { useCore } from '../../core/CoreContextProvider';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/Loading';
import log from '../../core/log';
import { endFunc } from '../../common/helper';
import { isToday } from '../../common/helper';
import { addDocToHistory } from '../../common/helper';
import { toast } from 'react-toastify';
import { where, collection, query } from 'firebase/firestore';
import { getDataByQuery } from '../../common/Firebase';

export const ScheduledSessions = ({
  schedule,
  popupFunction,
  getSessionData,
  coachDetails,
  zoomUrl,
}) => {
  const { firestore } = useCore();
  const [scheduledStatus, setScheduledStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const realTimeStatus = () => {
    const FIVE_MIN = 5 * 60 * 1000;
    const today = new Date();
    const startDates = schedule.scheduled_timestamp.toDate();
    const endDate = schedule.scheduled_timestamp.toDate();
    endDate.setMinutes(endDate.getMinutes() + Number(schedule.duration));

    if (
      startDates.getTime() - today.getTime() >= FIVE_MIN ||
      endDate.getTime() - today.getTime() >= FIVE_MIN
    ) {
      let givenTime;
      if (schedule.status === 'scheduled') {
        givenTime = startDates.getTime() - FIVE_MIN;
      } else if (schedule.status === 'inprogress') {
        givenTime = endDate.getTime() - FIVE_MIN;
      }
      const currTime = today.getTime();

      let milliseconds = givenTime - currTime;

      setTimeout(() => {
        getTodayStatus();
      }, milliseconds);
    }
    setLoading(false);
  };

  const findStartAndComp = (date, endTime, id, schedule) => {
    const data = isToday(date, endTime);
    if (data === 'completed') {
      updateField('completed', id);
      addToHistory(schedule);
    }
    return data;
  };

  const findEnd = (date, endTime, id, schedule) => {
    const data = endFunc(date, endTime);
    if (data === 'completed') {
      updateField('completed', id);
      addToHistory(schedule);
    }
    return data;
  };

  const addToHistory = async (schedule) => {
    setLoading(true);
    try {
      const sessionHistoryRef = collection(firestore, 'live_session_history');
      let q = query(
        sessionHistoryRef,
        where('session_id', '==', schedule.session_id),
        where('pod_id', '==', schedule.pod_id)
      );
      const sessionHistoryData = await getDataByQuery(q);
      if (!sessionHistoryData.length) {
        await addDocToHistory(firestore, schedule);
      }
      toast.success(
        `Session has been completed, you can check in pod session history at ${schedule.pod_id}`
      );
    } catch (error) {
      log('Firebase error: while adding history ', error);
    }

    getSessionData(coachDetails);
  };

  const updateField = async (status, docId) => {
    setLoading(true);
    try {
      await updateFieldInDocument(firestore, `live_session_schedule`, docId, {
        status: status,
      });
    } catch (error) {
      log('Firebase error: while updating schedule status', error);
    }
    setScheduledStatus(null);
    getSessionData(coachDetails);
  };

  const getTodayStatus = () => {
    let data;
    schedule.end_time = schedule.scheduled_timestamp.toDate();
    schedule.end_time.setMinutes(
      schedule.end_time.getMinutes() + Number(schedule.duration)
    );
    if (schedule.status === 'scheduled') {
      data = findStartAndComp(
        schedule.scheduled_timestamp,
        schedule.end_time,
        schedule.id,
        schedule
      );
    } else if (schedule.status === 'inprogress') {
      data = findEnd(
        schedule.scheduled_timestamp,
        schedule.end_time,
        schedule.id,
        schedule
      );
    }

    setScheduledStatus(data);

    return data;
  };

  useEffect(() => {
    getTodayStatus();
    realTimeStatus();
  }, [schedule]);

  const btnClick = () => {
    const value = getTodayStatus();
    if (value === 'Start') {
      updateField('inprogress', schedule.id);
    } else if (value === 'End') {
      updateField('completed', schedule.id);
      addToHistory(schedule);
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {Object.keys(schedule).length !== 0 && (
        <tr
          key={schedule.id}
          className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
        >
          <td
            className="text-sm text-blue-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer   underline"
            onClick={() => {
              popupFunction(schedule);
            }}
          >
            {schedule.session_name}
          </td>
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
            {schedule.start_date}
          </td>
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
            {schedule.start_time ? schedule.start_time : ''}
          </td>
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
            {schedule.coach_name}
          </td>
          <td
            className={`text-sm ${
              schedule.status === 'inprogress' ? ' text-green-500' : ''
            }  font-light px-6 py-4 whitespace-nowrap`}
          >
            {schedule.status}
          </td>
          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              {scheduledStatus ? (
                <button
                  onClick={() => {
                    btnClick();
                  }}
                  className={`${
                    scheduledStatus == 'Start'
                      ? 'bg-blue-900'
                      : scheduledStatus == 'completed'
                      ? 'bg-green-600'
                      : 'bg-red-600'
                  } text-white font-bold py-2 px-4 rounded-full font-bold py-2 px-4 rounded cursor-pointer`}
                >
                  {scheduledStatus}
                </button>
              ) : (
                <span className="text-gray-900 text-4xl font-light px-6 whitespace-nowrap ">
                  -
                </span>
              )}
            </div>
          </td>
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-blue-500 underline hover:text-blue-700 cursor-pointer">
            <a href={zoomUrl} target="_blank" rel="noopener noreferrer">
              {zoomUrl}
            </a>
          </td>
        </tr>
      )}
    </>
  );
};

ScheduledSessions.propTypes = {
  schedule: PropTypes.object.isRequired,
  popupFunction: PropTypes.func.isRequired,
  getSessionData: PropTypes.func.isRequired,
  coachDetails: PropTypes.any,
  zoomUrl: PropTypes.string,
};
