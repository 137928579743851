import { useAuth } from './AuthContextProvider';
import { Navigate } from 'react-router-dom';
import { useCore } from './CoreContextProvider';
import Layout from '../layout/Layout.page';
import LoadingSpinner from '../common/Loading';
import PropTypes from 'prop-types';

export const AuthenticatedRoute = ({ component: RouteComponent }) => {
  //const { coreLoading, roles } = useCore();
  const { coreLoading } = useCore();
  const roles = ['onlooker', 'coach'];
  const { currentUser, authLoading, userData } = useAuth();
  if (coreLoading || authLoading) {
    return <LoadingSpinner />;
  } else {
    const userHasRequiredRole =
      currentUser && roles.includes(userData.role) ? true : false;
    if (currentUser && userHasRequiredRole) {
      console.log('RENDER STUFF', currentUser);
      return (
        <Layout>
          <RouteComponent />
        </Layout>
      );
    }
    if (currentUser && !userHasRequiredRole) {
      return (
        <Layout>
          <div> Error Handling Request! </div>
        </Layout>
      );
    }
    return <Navigate to="/" />;
  }
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.any.isRequired,
};
