import { Routes, Route } from 'react-router-dom';
import AdminDashBoard from '../dashboard/coach/CoachDashBoard';
import LoginPage from '../login/Login.page';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { CoachPods } from '../Pods/CoachPods';
import { PodSessions } from '../Pods/PodSessions';
import { ScheduleCoach } from '../Schedule/ScheduleCoach';
import { UpcomingSessions } from '../Sessions/UpcomingSessions';
import ForgotPassword from '../login/ForgotPassword';
import ResetPassword from '../login/ResetPassword';
import PageNotFound from '../common/PageNotFound';

const TapoutsRoutes = () => {
  return (
    <Routes>
      <Route
        path="coach/pod/sessions"
        element={<AuthenticatedRoute component={PodSessions} />}
      />
      <Route
        path="/coach/sessions"
        element={<AuthenticatedRoute component={UpcomingSessions} />}
      />
      <Route
        path="/coach/pods"
        element={<AuthenticatedRoute component={CoachPods} />}
      />
      <Route
        path="/coach/schedule"
        element={<AuthenticatedRoute component={ScheduleCoach} />}
      />
      <Route path="/" element={<LoginPage />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route
        path="/dashboard"
        element={<AuthenticatedRoute component={AdminDashBoard} />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default TapoutsRoutes;
