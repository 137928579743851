import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getData } from '../../common/Firebase';
import { useCore } from '../../core/CoreContextProvider';
import { Participants } from './Participants';
import { getDataWithId } from '../../common/helper';
import log from '../../core/log';
export const HistorySessions = ({
  history,
  participants,
  popupFunction,
  podId,
  drop,
  clickHappens,
  setLoading,
}) => {
  const [coachName, setCoachName] = useState();
  const { firestore } = useCore();
  const [details, setDetails] = useState([]);
  const [FeedButtonToggle, setFeedButtonToggle] = useState(false);

  const getCoachName = async () => {
    const name = await getDataWithId(firestore, history.coach_uid, 'coaches');
    name && setCoachName(name);
  };

  useEffect(() => {
    getCoachName();
  }, [history]);

  useEffect(() => {
    getParticipants();
  }, []);

  const getParticipants = async () => {
    let arr = [];
    try {
      await Promise.all(
        participants.map(async (e) => {
          const details = await getData(firestore, e.id, 'children');
          const feedBackData = await getFeedback(details.uid);
          if (feedBackData) {
            details.feedback = feedBackData.feedback;
            details.feedbackId = feedBackData.id;
            details.present = feedBackData.present;
          }
          arr.push(details);
        })
      );
    } catch (error) {
      log('Firebase error: while getting participants', error);
    }
    setDetails(arr);
  };

  const getFeedback = async (child_id) => {
    try {
      const childFeedback = await getData(
        firestore,
        history.id,
        `children_pod_data/${child_id}/${podId}`
      );

      if (!childFeedback) {
        return false;
      } else {
        setFeedButtonToggle(true);
        return {
          feedback: childFeedback.feedback,
          id: childFeedback.id,
          present: Object.prototype.hasOwnProperty.call(
            childFeedback,
            'present'
          )
            ? childFeedback.present
            : false,
        };
      }
    } catch (error) {
      log('Firebase error: while getting childrens feedback', error);
    }
  };

  return (
    <>
      <tbody>
        <tr
          key={history.id}
          className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
        >
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap cursor-pointer">
            <span
              onClick={() => {
                clickHappens(history.id);
              }}
              className="pr-2 text-2xl"
            >
              {drop && drop === history.id ? '-' : '+'}
            </span>
            {history.session_name}
          </td>
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
            {history.completed_date}
          </td>
          <td className="text-sm text-gray-900  font-light px-6 py-4 whitespace-nowrap">
            {history.start_time}
          </td>
          <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
            {coachName}
          </td>
          <td className="text-sm text-green-500 font-light px-6 py-4 whitespace-nowrap">
            {history.status}
          </td>
          <td className="text-gray-900 font-light px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <a className="p-3 border mr-2">
                <span
                  onClick={() => {
                    popupFunction(history);
                  }}
                  className="bg-view block"
                ></span>
              </a>
            </div>
          </td>
        </tr>

        {drop && drop === history.id ? (
          <Participants
            details={details}
            setDetails={setDetails}
            session_doc_id={history.id}
            session_id={history.session_id}
            podId={podId}
            FeedButtonToggle={FeedButtonToggle}
            getParticipants={getParticipants}
            setLoading={setLoading}
          />
        ) : (
          ''
        )}
      </tbody>
    </>
  );
};
HistorySessions.propTypes = {
  history: PropTypes.object.isRequired,
  participants: PropTypes.array.isRequired,
  popupFunction: PropTypes.func.isRequired,
  podId: PropTypes.string.isRequired,
  drop: PropTypes.string,
  clickHappens: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
};
