import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';
import log from '../core/log';

export const getConfigurationData = async (firestore) => {
  const docRef = doc(firestore, 'app_config_data', 'core_configs');
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log('Documents are', docSnap.data());
    return docSnap.data();
  } else {
    console.log('No such document!');
    return false;
  }
};

export function getDocData(fist, docName) {
  return new Promise((resolve, reject) => {
    if (docName) {
      let docData = new Map();
      getDoc(docName)
        .then((doc) => {
          docData.set(doc.id, doc.data());
          resolve(docData);
        })
        .catch((err) => {
          log(1001, '<ERROR> on getDocs' + err);
          Promise.reject(new Error(`${docName} Failed to get data on getDocs`));
        });
    } else {
      log(
        1002,
        reject(
          '<ERROR> Failed to get Collection Data. Collection Does not exist' +
            docName
        )
      );
      Promise.reject(
        new Error(`${docName} Failed to get Document Data on getDocs`)
      );
    }
  });
}

export const addDocument = async (path, firestore, data) => {
  const docRef = await addDoc(collection(firestore, path), { ...data });
  if (docRef.id) {
    return docRef.id;
  } else {
    console.log('Firebase error: No document added');
    return false;
  }
};

export const updateDocument = async (docID, path, firestore, data) => {
  try {
    await setDoc(doc(firestore, path, docID), { ...data });
    return true;
  } catch (err) {
    console.log('Firebase :' + err);
    return false;
  }
};

export const getAllDocuments = async (firestore, path, subPath) => {
  const querySnapshot = await getDocs(collection(firestore, path));
  let arr = [];
  if (querySnapshot) {
    querySnapshot.forEach(async (doc) => {
      let data = doc.data();
      arr.push({ id: doc.id, ...data });
    });
    if (subPath) {
      arr.forEach(async (element, index) => {
        const subCollection = await getDocs(
          collection(firestore, path + '/' + element.id + '/' + subPath)
        );
        if (subCollection) {
          let contents = [];
          subCollection.forEach((doc) => {
            contents.push(doc.data());
          });
          arr[index] = { ...arr[index], contents };
        } else {
          console.log('firebase error: doc is not found');
        }
      });
    }
    return arr;
  } else {
    console.log('firebase error: doc is not found');
    return false;
  }
};

export const getData = async (firestore, docID, path, subPath) => {
  const docRef = doc(firestore, path, docID);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    let data = docSnap.data();
    data.id = docRef.id;
    if (subPath) {
      const subCollection = await getDocs(
        collection(firestore, path + '/' + docID + '/' + subPath)
      );
      if (subCollection) {
        let contents = [];
        subCollection.forEach((doc) => {
          contents.push({ ...doc.data(), id: doc.id });
        });
        data = { ...data, contents };
      } else {
        console.log('firebase error: doc is not found');
      }
    }
    return data;
  } else {
    console.log('No such document!');
    return false;
  }
};

export const getDataByQuery = async (query) => {
  const querySnapshot = await getDocs(query);
  let arr = [];
  if (querySnapshot) {
    querySnapshot.forEach(async (doc) => {
      arr.push({ id: doc.id, ...doc.data() });
    });
    return arr;
  } else {
    console.log('firebase error: doc is not found');
    return false;
  }
};

export const updateFieldInDocument = async (
  firestore,
  path,
  docID,
  payload
) => {
  await setDoc(doc(firestore, path, docID), payload, { merge: true });
};

export const deleteDocument = async (firestore, collectionName, docId) => {
  await deleteDoc(doc(firestore, collectionName, docId));
};
