import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import { useCore } from './CoreContextProvider.js';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from 'firebase/auth';
import log from './log';
import { useNavigate } from 'react-router';

export const AuthContext = React.createContext({});

const AuthContextProvider = ({ children }) => {
  let roles = ['coach', 'onlooker'];
  const { auth } = useCore();
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);

  const [authLoading, setAuthLoading] = useState(true);
  const navigation = useNavigate();
  const signIn = async (email, password) => {
    const result = await signInWithEmailAndPassword(auth, email, password);
    if (result) {
      const token = await result.user.getIdTokenResult();
      if (!token.claims || !roles.includes(token.claims.role)) {
        setCurrentUser(null);
        setUserData(null);
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const logOut = () => {
    if (auth) {
      signOut(auth);
      navigation('/');
    } else {
      log(40001, 'SORRY NO AUTH OBJECT' + currentUser);
    }
  };

  const forgotpassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const resetPassword = (oobCode, newPassword) => {
    return confirmPasswordReset(auth, oobCode, newPassword);
  };

  useEffect(() => {
    let tmpUserData = null;
    const unSubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((tokenResult) => {
          tmpUserData = {
            uid: user.uid,
            email: user.email,
            role: tokenResult.claims.role,
          };

          if (tokenResult.claims && roles.includes(tokenResult.claims.role)) {
            setUserData(tmpUserData);
            setCurrentUser(user);
          }
        });
      }
      setAuthLoading(false);
    });
    return unSubscribe;
  }, []);

  const value = {
    currentUser,
    userData,
    signIn,
    logOut,
    resetPassword,
    authLoading,
    setAuthLoading,
    forgotpassword,
    setCurrentUser,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;
