import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCore } from '../core/CoreContextProvider';
import { getData } from '../common/Firebase';
import ViewPodScripts from './ViewPodScripts';
import { HistorySessions } from './CoachPodSessions/HistorySessions';
import { ScheduledSessions } from './CoachPodSessions/ScheduledSessions';
import LoadingSpinner from '../common/Loading';
import log from '../core/log';
import { useAuth } from '../core/AuthContextProvider';
import { collection, query, where } from '@firebase/firestore';
import { getDataByQuery } from '../common/Firebase.js';
import {
  findHistorySessions,
  findUpcomingSessions,
  getDataWithId,
} from '../common/helper';

export const PodSessions = () => {
  const { userData } = useAuth();
  const [tabValue, setTabValue] = useState('first');
  const [schduleData, setScheduleData] = useState([]);
  const [historySessions, setHistorySessions] = useState([]);
  const [scheduleSessions, setScheduleSessions] = useState({});
  const [popup, setPopup] = useState({ show: false, data: null });
  const [loading, setLoading] = useState(true);
  const [coachDetails, setCoachDetails] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [zoomUrl, setZoomUrl] = useState('');
  const [drop, setDrop] = useState('');
  const location = useLocation();
  const { firestore } = useCore();
  const { id } = location.state || {};

  const getSessionData = async (coachDetails) => {
    try {
      if (id) {
        const pod = await getData(firestore, id, 'pods');
        const liveSchedule = await findUpcomingSessions(
          firestore,
          userData,
          coachDetails,
          id
        );

        const liveHistory = await findHistorySessions(
          firestore,
          userData,
          id,
          coachDetails
        );
        setZoomUrl(pod.start_url ?? '');
        setParticipants(pod.children);
        setScheduleData(liveSchedule);
        setHistorySessions(liveHistory);
      } else {
        setLoading(false);
      }
    } catch (error) {
      log('Firebase error: while getting pod  data', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCoachDetails();
  }, []);

  const getCoachDetails = async () => {
    try {
      const coachRef = collection(firestore, 'coaches');
      const q = query(coachRef, where('uid', '==', userData.uid));
      const [coachDetails] = await getDataByQuery(q);
      await getSessionData(coachDetails);
      setCoachDetails(coachDetails);
      setLoading(false);
    } catch (err) {
      log('Error while getting coach data' + err);
      setLoading(false);
    }
  };

  const getCoachName = async (schedule) => {
    const name = await getDataWithId(firestore, schedule.coach_uid, 'coaches');
    if (name) schedule.coach_name = name;
    setScheduleSessions(schedule);
    setLoading(false);
  };

  useEffect(() => {
    if (schduleData?.length) {
      schduleData.sort(function (a, b) {
        const dateA = new Date(`${a.start_date} ${a.start_time}`),
          dateB = new Date(`${b.start_date} ${b.start_time}`);
        return dateA - dateB;
      });
      const [schedule] = schduleData;
      getCoachName(schedule);
    } else {
      setScheduleSessions({});
    }
  }, [schduleData]);

  const tabHandler = (index) => {
    setTabValue(index);
  };

  const popupFunction = (data) => {
    setPopup({
      show: true,
      data: data,
    });
  };

  const clickHappens = (id) => {
    if (drop === id) {
      setDrop(null);
    } else {
      setDrop(id);
    }
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      {!loading && (
        <div className="overflow-hidden">
          <div className="content relative min-h-screen flex-1 px-6 pt-4 pb-10 bg-indigo-50">
            <div className="intro-y flex items-center mt-6">
              <h2 className="text-2xl font-medium mr-auto">Manage Pod</h2>
            </div>
            <div className="grid grid-cols-12 gap-6 mt-5">
              <div className="col-span-12 lg:col-span-12">
                <div className="bg-white shadow-md rounded-md mt-5">
                  <div id="input" className="p-5">
                    <div className="preview">
                      <div className="tabs-section">
                        <ul
                          id="tabs"
                          className="inline-flex pt-2 w-full border-b"
                        >
                          <li
                            className={
                              tabValue == 'first'
                                ? 'bg-white px-5 text-gray-800 font-semibold py-3 rounded-t border-t border-r border-l -mb-px'
                                : 'px-5 text-gray-800 font-semibold py-3 rounded-t'
                            }
                          >
                            <button onClick={() => tabHandler('first')}>
                              Scheduled
                            </button>
                          </li>
                          <li
                            className={
                              tabValue == 'second'
                                ? 'bg-white px-5 text-gray-800 font-semibold py-3 rounded-t border-t border-r border-l -mb-px'
                                : ' px-5 text-gray-800 font-semibold py-3 rounded-t'
                            }
                          >
                            <button
                              id="default-tab"
                              onClick={() => tabHandler('second')}
                            >
                              History
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div
                        style={{ display: tabValue == 'first' ? '' : 'none' }}
                        id="tab-content"
                        className="border border-t-0"
                      >
                        {scheduleSessions &&
                        Object.keys(scheduleSessions).length !== 0 ? (
                          <div id="third" className="p-5 overflow-x-scroll">
                            <div className="w-full mx-auto my-0 text-left flex mt-5">
                              <div className="table-section w-full">
                                <table className="min-w-full border" id="table">
                                  <thead className="">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Session Name
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Date
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Time
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Coach
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Status
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Action
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-center uppercase bg-indigo-50 border-b"
                                      >
                                        Zoom Url
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <ScheduledSessions
                                      schedule={scheduleSessions}
                                      popupFunction={popupFunction}
                                      getSessionData={getSessionData}
                                      coachDetails={coachDetails}
                                      zoomUrl={zoomUrl}
                                    />
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="p-5">No Schedule Sessions</div>
                        )}
                      </div>
                      <div
                        style={{
                          display: tabValue == 'second' ? '' : 'none',
                        }}
                        id="tab-content"
                        className="border border-t-0"
                      >
                        {historySessions && historySessions.length !== 0 ? (
                          <div id="third" className="p-5">
                            <div className="w-full mx-auto my-0 text-left flex mt-5">
                              <div className="table-section w-full">
                                <table className="min-w-full border" id="table">
                                  <thead className="">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Session Name
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Date
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Time
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Coach
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Status
                                      </th>
                                      <th
                                        scope="col"
                                        className="text-sm font-medium text-gray-900 px-6 py-4 text-left uppercase bg-indigo-50 border-b"
                                      >
                                        Action
                                      </th>
                                    </tr>
                                  </thead>

                                  {historySessions.map((history) => {
                                    return (
                                      <React.Fragment key={history.id}>
                                        <HistorySessions
                                          history={history}
                                          participants={participants}
                                          popupFunction={popupFunction}
                                          podId={id}
                                          drop={drop}
                                          clickHappens={clickHappens}
                                          setLoading={setLoading}
                                        />
                                      </React.Fragment>
                                    );
                                  })}
                                </table>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="p-5">No sessions Found</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ViewPodScripts setPopup={setPopup} popup={popup} />
        </div>
      )}
    </>
  );
};
