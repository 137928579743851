import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from '../core/AuthContextProvider';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import LoadingSpinner from '../common/Loading';

const ResetPassword = () => {
  const navigation = useNavigate();
  const { resetPassword } = useAuth();
  const [successMsg, setSuccessMsg] = useState(false);
  const [count, setCount] = useState(5);
  const [render, setRender] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [loading, setLoading] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  useEffect(() => {
    if (!query.get('oobCode')) {
      navigation('/');
    } else {
      setRender(true);
    }
  }, []);

  async function onSubmit(data) {
    setLoading(true);
    const password = data.password;
    const oobCode = query.get('oobCode');

    try {
      await resetPassword(oobCode, password);
      setSuccessMsg(true);
      setInterval(() => {
        setCount((oldTime) => {
          if (oldTime === 0) {
            navigation('/');
          }
          return oldTime - 1;
        });
      }, 1000);
    } catch (error) {
      setLoading(false);
      toast.error('Problem in resetting password . Please try again later');
      console.log(error.message);
    }
  }

  return (
    <>
      {!render && <LoadingSpinner />}
      {render && (
        <div className="w-full">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col min-h-screen bg-black px-10">
              <Link to="/" className="p-4 pt-12 hidden md:flex">
                <img alt="" className="w-56 h-20" src="./images/tapouts.png" />
              </Link>
              <div className="my-auto">
                <div className="text-white font-medium text-4xl leading-tight">
                  A few more clicks to
                  <br />
                  sign in to your account.
                </div>
              </div>
            </div>
            <div className="my-auto mx-auto xl:ml-20  px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
              <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                Reset Your Password?
              </h2>
              {successMsg && (
                <div className=" w-4/5 mt-2">
                  Your password has been reset successfully.You will be Redirect
                  to Login page in{' '}
                  <span className="text-blue-500 underline">{count} </span>
                  seconds .
                </div>
              )}

              {!successMsg && (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="intro-x mt-8">
                    <input
                      className="form-control w-full border rounded-md shadow-md py-4 px-5 border border-black-300 mb-5 outline-none"
                      type="password"
                      placeholder="password"
                      {...register('password', {
                        required: true,
                        minLength: 6,
                      })}
                    />
                    {errors.password && (
                      <div>*Please enter the 6 digit password</div>
                    )}
                    <input
                      className="form-control w-full border rounded-md shadow-md py-4 px-5 border border-black-300 mb-5 outline-none"
                      placeholder="confirmPassword"
                      type="password"
                      {...register('confirmPassword', {
                        required: true,
                        validate: (val) => {
                          if (watch('password') != val) {
                            return 'Your passwords do no match';
                          }
                        },
                      })}
                    />
                    {errors.confirmPassword && (
                      <div>*Your passwords do no match</div>
                    )}
                    {/* include validation with required or other standard HTML validation rules */}
                  </div>

                  <div className="flex text-gray-700 justify-between dark:text-gray-600 text-xs sm:text-sm mt-6">
                    <div className="button">
                      <input
                        disabled={loading}
                        type="submit"
                        className="w-36 px-6 py-3 text-lg cursor-pointer text-white transition-all duration-150 ease-linear rounded-md shadow outline-none bg-blue-900 hover:bg-yellow-500 hover:shadow-lg focus:outline-none"
                      />
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
