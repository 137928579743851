import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const SubMenuItem = ({ label, target, id }) => {
  const subMenuItemStyle = 'group';

  return (
    <li
      className="relative grid bg-indigo-50 last:border-b-0 border-b border-gray-300"
      key={id}
    >
      <Link to={target} className={subMenuItemStyle}>
        <div className="space-y-1">
          <p
            className="text-base px-5 py-3 font-medium leading-6 group-hover:text-white 
                                 group-hover:bg-blue-900"
          >
            {label}
          </p>
        </div>
      </Link>
    </li>
  );
};

SubMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default SubMenuItem;
