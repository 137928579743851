import { Link } from 'react-router-dom';

export const Logo = () => {
  return (
    <div className="main-logo mr-auto">
      <Link
        to="/dashboard"
        className="-intro-x md:flex xl:w-[180px] block mr-auto"
      >
        <img alt="Tapouts" className="w-32 h-8" src="/images/tapouts.png" />
      </Link>
    </div>
  );
};
